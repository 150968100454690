<template>
  <div class="about-us">
    <div
      class="page-header d-flex align-items-center justify-content-center"
      style="background: url('img/aboutus/header-about.jpg'); background-size: cover"
    >
      <div class="viewport">
        <h1>We are comstream</h1>
        <p>
          An effective blend of Scandinavian working culture and skilled IT professionals, helping
          companies grow and succeed through intelligent talent placement.
        </p>
      </div>
      <div class="overlay"></div>
    </div>
    <div class="viewport">
      <div class="who-we-are">
        <h3>Who we are</h3>
        <div class="content">
          <h4>A modern company with modern solutions</h4>
          <p>
            Founded in 2010, we are a privately held company group with more than 70 employees in
            our Swedish and Bulgarian offices. By focusing on building long-term partnerships with
            our customers, we enable them to evolve, manage and run their technical operations.
          </p>
          <p>
            Our placements in Sweden and Bulgaria allows us to connect two of Europe’s most dynamic
            IT hubs, where our daily work sees us sourcing, screening, recruiting and placing
            high-calibre technology professionals for our customers .
          </p>
        </div>
        <div class="d-flex justify-content-between boxes">
          <div class="box">
            <img class="title-img" src="img/icons/icons8-task-100.png" alt="mission" />
            <h3>Mission</h3>
            <p>
              Our mission is to help our customers grow and manage their technical organizations.
            </p>
            <div
              class="big-box"
              style="background: url(img/aboutus/corporate-social.jpg); background-size: cover"
            >
              <div class="box-content">
                <h4>Corporate Social Responsibility</h4>
                <p>
                  We stand for equality between people and mutual respect and understanding for each
                  other. Our business is conducted in compliance with all relevant laws, with highly
                  ethical practices at the same time.
                </p>
                <router-link to="/corporate-social-responsibility" class="btn btn-primary"
                  >Read more</router-link
                >
              </div>
              <div class="overlay"></div>
            </div>
          </div>
          <div class="box">
            <img class="title-img" src="img/icons/icons8-iceberg-100.png" alt="mission" />
            <h3>Vision</h3>
            <p>
              Our vision is to become a unique and invaluable partner for leading technology
              companies.
            </p>
            <div
              class="big-box"
              style="background: url(img/careers/working-style.jpg); background-size: cover"
            >
              <div class="box-content">
                <h4>The Scandinavian way of working</h4>
                <p>
                  Scandinavian workplace culture is based on a mix of teamwork and individual
                  responsibility. A strict hierarchy is absent, and each role in the group is viewed
                  as equally important, where consensus is generally central to decision-making.
                </p>
                <router-link to="/careers" class="btn btn-primary">Read more</router-link>
              </div>
              <div class="overlay"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="who-we-work-with">
        <h3>Who we work with</h3>
        <p>
          Our customers include growth businesses that understand the value of attracting top
          talent. Today, we have over 200 clients who are cloud service providers and Internet
          companies in many niches, including online marketing, fintech, social networking, gaming,
          e-commerce, infrastructure, telematics, telecom, automotive, ERP, IT security,
          geographical information systems and more
        </p>
      </div>
      <div class="call-to-action-boxes d-flex justify-content-between" style="padding-top: 20px">
        <div
          class="box"
          style="background: url('img/aboutus/success-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3>Attracting talent is the road to success</h3>
            <p>
              From nearshoring and direct hiring through to a robust roster of freelancers, we are
              here to connect talent to success.
            </p>
            <router-link to="/services" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>

        <div
          class="box"
          style="background: url('img/aboutus/journey-bg.jpg'); background-size: cover"
        >
          <div class="content">
            <h3 style="margin-bottom: 43px">Start your journey with us</h3>
            <p>
              It all starts with hello. If you’re ready to take your business to the next level, get
              in touch with your requirements.
            </p>
            <router-link to="/contact-us" class="btn btn-primary">Read more</router-link>
          </div>
          <div class="overlay"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import titleMixin from '../mixins/titleMixin';
export default {
  mixins: [titleMixin],
  title: 'Our Mission, Values and History | Comstream',
  mounted() {
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
  },
};
</script>
<style lang="scss" scoped>
.about-us {
  .who-we-are {
    padding: 70px 0;
    h3 {
      text-align: center;
      color: #383838;
      font-weight: 700;
      font-size: 38px;
      margin-bottom: 40px;
    }
    .content {
      h4 {
        color: #383838;
        font-size: 28px;
        font-weight: 700;
        margin-bottom: 30px;
      }
    }
    .boxes {
      .box {
        width: 49%;
        .title-img {
          width: 100px;
          height: 100px;
          display: block;
          margin: 12px auto;
        }
        h3 {
          text-align: center;
        }
        p {
          text-align: center;
        }
        .big-box {
          position: relative;
          height: 690px;
          margin-top: 12px;
          border-radius: 5px;
          overflow: hidden;
          .box-content {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 20px 20px 40px 20px;
            z-index: 1;
            text-align: center;
            h4 {
              font-size: 28px;
              font-weight: 700;
              color: #fff;
              margin-bottom: 30px;
            }
            p {
              font-size: 18px;
              color: #fff;
              margin-bottom: 40px;
            }
          }
          .overlay {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1));
            z-index: 0;
          }
        }
      }
    }
  }
  .who-we-work-with {
    text-align: center;
    h3 {
      font-weight: 700;
      font-size: 38px;
      margin-bottom: 40px;
    }
    p {
      font-size: 18px;
      margin-bottom: 45px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .about-us {
    .who-we-are {
      h3 {
        font-size: 2rem;
      }
      .boxes {
        display: block !important;
        .box {
          width: 100%;
          &:first-of-type {
            margin-bottom: 40px;
          }
        }
      }
    }
    .who-we-work-with {
      h3 {
        font-size: 2rem;
      }
    }
  }
}
</style>
